import React from 'react';
import { useMemo } from 'react';
import { AdminQuestion } from '../models/AdminQuestion';
import { ChoiceQuestionResponse } from '../models/ChoiceQuestionResponse';
import { IComment } from '../models/Comment';
import { LiveFeedQuestionResponse } from '../models/LiveFeedQuestionResponse';
import { ILivePollSession } from '../models/LivePollSession';
import { LivePollSessionState } from '../models/LivePollSessionState';
import { IQuestion } from '../models/Question';
import { IQuestionResponseTimer } from '../models/QuestionResponseTimer';
import { StartQuestionCountdown } from '../models/StartQuestionCountdown';
import { IRespondent } from '../models/Respondent';
import styles from './takeLivePollStateContext.module.css';
import { pageReload } from '../screens/utils/reloadUtil';
import { getClientServerTimeDiffInMs } from '../screens/utils/timeUtil';
import { IProfileQuestion } from '../models/ProfileQuestion';
import { ProfileQuestionResponse } from '../models/ProfileQuestionResponse';
import { useAllowOneSocketConnection } from './allowOneSocketConnectionContext';
import { Spinner } from '../components/spinner/Spinner';
import { removeSessionInfo } from '../components/util/manageSessionInfo.util';
import { DifficultyLevel } from '../screens/games/models/difficulty-level.enum';
import { WordGameWords } from '../models/WordGameWords';
import { MultiSelectQuestionResponse } from '../models/MultiSelectQuestionResponse';
import { LivePollSessionType } from '../models/LivePollSessionType';
import { IPermanentRoom } from '../models/PermanentRoom';
import { ISwipeQuestion } from '../models/SwipeQuestion';
import { SwipeQuestionOptionSide } from '../models/SwipeQuestionOptionSide.enum';
import { ISwipeQuestionResponse } from '../models/SwipeQuestionResponse.interface';

interface ServerState {
  respondent: IRespondent;
  livePollSession?: ILivePollSession;
  questionResponseTimer?: IQuestionResponseTimer;
  question?: IQuestion;
  questionResponse?: ChoiceQuestionResponse | MultiSelectQuestionResponse;
  liveFeedQuestionResponse?: LiveFeedQuestionResponse;
  liveFeedVotes?: Record<number, LiveFeedVotes>;
  score: number;
  rank: number;
  previousRank: number;
  startQuestionCountdown?: StartQuestionCountdown;
  leaderboard: RespondentScoreEncoded[];
  teamLeaderboard?: TeamStats[];
  profileQuestions?: IProfileQuestion[];
  profileQuestionsAnsweredCount?: number;
  wordCount?: number;
  wordGameLeaderboard?: WordGameLeaderboardEncoded[];
  wordCloudResponseCount?: number;
  wordCloudResponses?: IWordCloudResponse[];
  connectedRespondents?: number;
  permanentRoom?: IPermanentRoom;
  swipeQuestionResponse?: ISwipeQuestionResponse;
}

export type RespondentScoreEncoded = [number, number, string, number, string]; // respondentId, rank, respondentName, score, teamColor

type WordGameLeaderboardEncoded = RespondentScoreEncoded;

export interface TeamStats {
  teamUid: string;
  teamScore: string;
}

interface LiveFeedVotes {
  commentId: number;
  isUpVote: boolean;
  isDownVote: boolean;
}

interface ContextValue {
  loadingSession: boolean;
  serverState: ServerState;
  clientServerTimeDiffInMs: number;
  submitAnswer(
    type: LivePollSessionType,
    questionId: string,
    answerId: string,
  ): Promise<void>;
  submitMultiSelectAnswerIds(
    type: LivePollSessionType,
    questionId: string,
    answerIds: string[],
  ): Promise<void>;
  submitComment(
    type: LivePollSessionType,
    questionId: string,
    text: string,
  ): Promise<void>;
  submitWordCloudAnswer(
    type: LivePollSessionType,
    questionId: string,
    text: string,
  ): Promise<void>;
  submitSwipeQuestion(
    type: LivePollSessionType,
    questionId: string,
    cardId: string,
    selectedSide: SwipeQuestionOptionSide,
  ): Promise<void>;
  submitCommentVotes(commentId: number, isUpvote: boolean): Promise<void>;
  submitProfileQuestionAnswer(
    submitProfileQuestionAnswer: SubmitProfileQuestionAnswer,
  ): Promise<void>;
  submitWordGameWord(): Promise<void>;
  submitLastProfileQuestionAnswer(
    submitProfileQuestionAnswer: SubmitProfileQuestionAnswer,
  ): Promise<void>;
  changeRespondentTeam(teamUid: string): Promise<void>;
  getWordsForWordGame(
    wordGameLevel: DifficultyLevel,
    browserLangs: readonly string[],
  ): Promise<WordGameWords>;
  getQuestionForPermanentRoom(): Promise<void>;
  error: Error | undefined;
}

interface StartQuestionEventData {
  questionResponseTimer: IQuestionResponseTimer;
  question: IQuestion | ISwipeQuestion;
  livePollSession: ILivePollSession;
}

export interface IWordCloudResponse {
  name: string;
  weight: number;
}
interface FinishQuestionEventData {
  question: AdminQuestion;
  livePollSession: ILivePollSession;
  respondentScores?: RespondentScoreEncoded[];
  wordCloudResponses?: IWordCloudResponse[];
  teamLeaderboard?: TeamStats[];
}
interface Props {
  children: React.ReactNode;
}

export interface SubmitProfileQuestionAnswer {
  questionId: string;
  answerId: string;
}

export const EVENT_LIVEFEED_COMMENT_RECEIVED_UPVOTE =
  'respondent:received.livefeed.comment.upvote';
export const EVENT_LIVEFEED_COMMENT_RECEIVED_DOWNVOTE =
  'respondent:received.livefeed.comment.downvote';
export const EVENT_LIVEFEED_COMMENT_RECEIVED =
  'respondent:received.livefeed.comment';
export const EVENT_SUBMIT_LIVEFEED_ANSWER =
  'respondent:answer.livefeed.question';
export const EVENT_SUBMIT_WORD_CLOUD_ANSWER =
  'respondent:answer.word-cloud.question';
export const EVENT_SUBMIT_SWIPE_ANSWER = 'respondent:answer.swipe.question';
export const EVENT_SUBMIT_ANSWER = 'respondent:answer.question';
export const EVENT_SUBMIT_MULTISELECT_ANSWER =
  'respondent:answer.multiselect.question';
export const EVENT_SUBMIT_PROFILE_QUESTION_ANSWER =
  'respondent:answer.profile.question';
export const EVENT_SUBMIT_LAST_PROFILE_QUESTION_ANSWER =
  'respondent:answer.last.profile.question';
export const EVENT_FINISH_QUESTION = 'respondent:finish.question';
export const EVENT_SYNC_LIVEPOLL_SESSION_STATE = 'respondent:sync';
export const EVENT_START_QUESTION = 'respondent:start.question';
export const EVENT_START_QUESTION_COUNTDOWN =
  'respondent:start.question.countdown';
export const EVENT_LIVEPOLL_SESSION_FINISHED =
  'respondent:livepoll-session.finished';
export const EVENT_LIVEPOLL_SESSION_TERMINATED =
  'respondent:livepoll-session.terminated';
export const EVENT_SUBMIT_VOTES = 'respondent.submit.votes';
export const EVENT_RECEIVED_VOTES = 'respondent.received.votes';
export const RESPONDENT_EVENT_WORD_GAME_WORD_SUBMIT =
  'respondent:word-game.submit.word';
export const RESPONDENT_EVENT_CHANGE_RESPONDENT_TEAM_UID =
  'respondent:change.respondent.team.uid';
export const RESPONDENT_EVENT_RECEIVED_RESPONDENT_TEAM_UID =
  'respondent:received.respondent.team.uid';
export const RESPONDENT_EVENT_WORD_GAME_GET_WORDS =
  'respondent:word-game.get.words';
export const EVENT_OTHER_RESPONDENT_JOINED = 'respondent:join.other.respondent';
export const RESPONDENT_EVENT_GET_PERMANENT_ROOM_QUESTION =
  'respondent:get.permanent-room-question';

const handleSocketError = (
  handler: (data: any) => void,
  setError: (error: Error) => void,
) => {
  return (data: any) => {
    if (data.type === 'error') {
      setError(new Error(data.message));
      return;
    }

    handler(data);
  };
};

const TakeLivePollStateContext = React.createContext<ContextValue | null>(null);
const errorMessages = ['Already commented', 'Duplicate response error'];

const handleRefresh = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  pageReload();
};

const refreshMessage = (
  <span>
    Click{' '}
    <a href="/" onClick={handleRefresh} data-testid="reloadPage">
      here
    </a>{' '}
    to refresh the page
  </span>
);

export const TakeLivePollStateProvider = ({ children }: Props) => {
  const { socket } = useAllowOneSocketConnection();
  const [error, setError] = React.useState<Error | undefined>();
  const [loadingSession, setLoadingSession] = React.useState<boolean>(true);

  const [serverState, setServerState] = React.useState<
    ServerState | undefined
  >();
  const [clientServerTimeDiffInMs, setClientServerTimeDiffInMs] =
    React.useState<number>(0);

  const submitComment = React.useCallback(
    (type: LivePollSessionType, questionId: string, text: string) => {
      return new Promise<void>(resolve => {
        const questionResponseTimerId =
          serverState!.livePollSession?.questionResponseTimerId;

        socket.emit(
          EVENT_SUBMIT_LIVEFEED_ANSWER,
          {
            type,
            questionResponseTimerId,
            questionId,
            text,
          },
          handleSocketError((data: LiveFeedQuestionResponse) => {
            setServerState({
              ...serverState!,
              liveFeedQuestionResponse: { ...data },
            });
            resolve();
          }, setError),
        );
      });
    },
    [socket, serverState],
  );

  const submitWordCloudAnswer = React.useCallback(
    (type: LivePollSessionType, questionId: string, answer: string) => {
      return new Promise<void>(resolve => {
        const questionResponseTimerId =
          serverState!.livePollSession?.questionResponseTimerId;
        socket.emit(
          EVENT_SUBMIT_WORD_CLOUD_ANSWER,
          {
            type,
            questionResponseTimerId,
            questionId,
            answer,
          },
          handleSocketError(() => {
            setServerState(oldState => {
              let wordCloudResponseCount = 1;
              if (oldState?.wordCloudResponseCount) {
                wordCloudResponseCount = oldState.wordCloudResponseCount + 1;
              }
              return {
                ...oldState!,
                wordCloudResponseCount,
              };
            });
            resolve();
          }, setError),
        );
      });
    },
    [serverState, socket],
  );

  const submitSwipeQuestion = React.useCallback(
    (
      type: LivePollSessionType,
      questionId: string,
      cardId: string,
      selectedSide: SwipeQuestionOptionSide,
    ) => {
      return new Promise<void>(resolve => {
        const questionResponseTimerId =
          serverState!.livePollSession?.questionResponseTimerId;
        socket.emit(
          EVENT_SUBMIT_SWIPE_ANSWER,
          {
            type,
            questionResponseTimerId,
            questionId,
            cardId,
            selectedSide,
          },
          handleSocketError((data: ISwipeQuestionResponse) => {
            const questionIdInState = serverState?.question?.id;
            if (data.questionId === questionIdInState) {
              setServerState(oldState => {
                return {
                  ...oldState!,
                  swipeQuestionResponse: data,
                };
              });
            }
            resolve();
          }, setError),
        );
      });
    },
    [serverState, socket],
  );

  const submitAnswer = React.useCallback(
    (type: LivePollSessionType, questionId: string, answerId: string) => {
      return new Promise<void>(resolve => {
        const questionResponseTimerId =
          serverState!.livePollSession?.questionResponseTimerId;

        socket.emit(
          EVENT_SUBMIT_ANSWER,
          {
            type,
            questionId,
            answerId,
            questionResponseTimerId,
          },
          handleSocketError((data: ChoiceQuestionResponse) => {
            setServerState({
              ...serverState!,
              questionResponse: { ...data },
            });
            resolve();
          }, setError),
        );
      });
    },
    [socket, serverState],
  );

  const submitCommentVotes = React.useCallback(
    (commentId: number, isUpVote: boolean) => {
      const vote = isUpVote ? 1 : 0;
      return new Promise<void>(resolve => {
        socket.emit(
          EVENT_SUBMIT_VOTES,
          {
            commentId,
            isUpVote: vote,
          },
          handleSocketError(
            (data: { comment: IComment; voteResponse: any }) => {
              setServerState(oldState => {
                let oldLiveFeedVotes = oldState!.liveFeedVotes!;
                if (!oldLiveFeedVotes) {
                  oldLiveFeedVotes = {};
                }

                if (data.voteResponse !== null) {
                  const res = {
                    commentId: data.comment.id,
                    isUpVote: isUpVote,
                    isDownVote: !isUpVote,
                  };

                  oldLiveFeedVotes[data.comment.id] = res;

                  const updatedState = {
                    ...oldState!,
                    liveFeedVotes: { ...oldLiveFeedVotes },
                  };
                  return updatedState;
                } else {
                  delete oldLiveFeedVotes[data.comment.id];
                  const updatedState = {
                    ...oldState!,
                    liveFeedVotes: { ...oldLiveFeedVotes },
                  };
                  return updatedState;
                }
              });
              resolve();
            },
            setError,
          ),
        );
      });
    },
    [socket],
  );
  const submitProfileQuestionAnswer = React.useCallback(
    (submitProfileQuestionAnswer: SubmitProfileQuestionAnswer) => {
      return new Promise<void>(resolve => {
        socket.emit(
          EVENT_SUBMIT_PROFILE_QUESTION_ANSWER,
          submitProfileQuestionAnswer,
          handleSocketError((data: ProfileQuestionResponse) => {
            setServerState(oldState => {
              const profileQuestionsAnsweredCount =
                oldState?.profileQuestionsAnsweredCount
                  ? oldState.profileQuestionsAnsweredCount + 1
                  : 1;
              const updatedState = {
                ...oldState!,
                profileQuestionsAnsweredCount,
              };
              return updatedState;
            });
            resolve();
          }, setError),
        );
      });
    },
    [socket],
  );
  const submitWordGameWord = React.useCallback(() => {
    return new Promise<void>(resolve => {
      socket.emit(
        RESPONDENT_EVENT_WORD_GAME_WORD_SUBMIT,
        {},
        handleSocketError(
          (data: {
            wordCount: number;
            wordGameLeaderboard: WordGameLeaderboardEncoded[];
          }) => {
            setServerState(oldState => {
              const updatedState = {
                ...oldState!,
                wordCount: data.wordCount,
                wordGameLeaderboard: data.wordGameLeaderboard,
              };
              return updatedState;
            });
            resolve();
          },
          setError,
        ),
      );
    });
  }, [socket]);

  const submitLastProfileQuestionAnswer = React.useCallback(
    (submitProfileQuestionAnswer: SubmitProfileQuestionAnswer) => {
      return new Promise<void>(resolve => {
        socket.emit(
          EVENT_SUBMIT_LAST_PROFILE_QUESTION_ANSWER,
          submitProfileQuestionAnswer,
          handleSocketError((data: ProfileQuestionResponse) => {
            setServerState(oldState => {
              const profileQuestionsAnsweredCount =
                oldState?.profileQuestionsAnsweredCount
                  ? oldState.profileQuestionsAnsweredCount + 1
                  : 1;
              const updatedState = {
                ...oldState!,
                profileQuestionsAnsweredCount,
              };
              return updatedState;
            });
            resolve();
          }, setError),
        );
      });
    },
    [socket],
  );

  const changeRespondentTeam = React.useCallback(
    (teamUid: string) => {
      return new Promise<void>(resolve => {
        socket.emit(
          RESPONDENT_EVENT_CHANGE_RESPONDENT_TEAM_UID,
          { teamUid: teamUid },
          handleSocketError(() => {
            setServerState(oldState => {
              const respondent = serverState!.respondent!;
              let updatedRespondent = { ...respondent, teamUid }!;
              const updatedState = {
                ...oldState!,
                respondent: updatedRespondent,
              };
              return updatedState;
            });
            resolve();
          }, setError),
        );
      });
    },
    [serverState, socket],
  );

  const getWordsForWordGame = React.useCallback(
    (wordGameLevel: DifficultyLevel, browserLangs: readonly string[]) => {
      return new Promise<WordGameWords>(resolve => {
        socket.emit(
          RESPONDENT_EVENT_WORD_GAME_GET_WORDS,
          {
            wordGameLevel,
            browserLangs,
          },
          handleSocketError((data: WordGameWords) => {
            resolve(data);
          }, setError),
        );
      });
    },
    [socket],
  );

  const submitMultiSelectAnswerIds = React.useCallback(
    (type: LivePollSessionType, questionId: string, answerIds: string[]) => {
      return new Promise<void>(resolve => {
        const questionResponseTimerId =
          serverState!.livePollSession?.questionResponseTimerId;

        socket.emit(
          EVENT_SUBMIT_MULTISELECT_ANSWER,
          {
            type,
            questionId,
            answerIds,
            questionResponseTimerId,
          },
          handleSocketError((data: MultiSelectQuestionResponse) => {
            setServerState({
              ...serverState!,
              questionResponse: { ...data },
            });
            resolve();
          }, setError),
        );
      });
    },
    [serverState, socket],
  );

  const getQuestionForPermanentRoom = React.useCallback(() => {
    return new Promise<void>(resolve => {
      const questionId = serverState!.question?.id;
      socket.emit(
        RESPONDENT_EVENT_GET_PERMANENT_ROOM_QUESTION,
        { currentQuestionId: questionId },
        handleSocketError(
          (questionWithLivePollSessionState: {
            question: IQuestion;
            state: LivePollSessionState;
          }) => {
            const updatedLivePollSession = {
              ...serverState!.livePollSession,
              state: questionWithLivePollSessionState.state,
            };

            setServerState({
              ...serverState!,
              question: questionWithLivePollSessionState.question,
              livePollSession: updatedLivePollSession as ILivePollSession,
              wordCloudResponseCount: 0,
            });
            resolve();
          },
          setError,
        ),
      );
    });
  }, [serverState, socket]);

  const calculateClientServerTimeDiffInMs = async () => {
    const clientServerTimeDiffInMs = await getClientServerTimeDiffInMs();
    setClientServerTimeDiffInMs(clientServerTimeDiffInMs);
  };

  React.useEffect(() => {
    calculateClientServerTimeDiffInMs();
    socket.emit(
      EVENT_SYNC_LIVEPOLL_SESSION_STATE,
      {},
      handleSocketError((data: ServerState) => {
        setServerState({ ...data });
        setLoadingSession(false);
      }, setError),
    );

    socket.on(
      EVENT_START_QUESTION,
      handleSocketError((data: StartQuestionEventData) => {
        setServerState(oldState => ({
          ...oldState!,
          ...data,
          questionResponse: undefined,
          liveFeedQuestionResponse: undefined,
          liveFeedVotes: undefined,
          swipeQuestionResponse: undefined,
        }));
      }, setError),
    );
    socket.on(
      RESPONDENT_EVENT_RECEIVED_RESPONDENT_TEAM_UID,
      handleSocketError((data: { respondentId: number; teamUid: string }) => {
        setServerState(oldState => {
          const respondent = oldState?.respondent!;
          const teamUid = data.teamUid;
          if (respondent.id === data.respondentId) {
            const updatedRespondent = { ...respondent, teamUid };
            const updatedState = {
              ...oldState!,
              respondent: updatedRespondent,
            };

            return updatedState;
          }
          return oldState;
        });
      }, setError),
    );

    socket.on(
      EVENT_START_QUESTION_COUNTDOWN,
      handleSocketError((data: StartQuestionCountdown) => {
        setServerState(oldState => ({
          ...oldState!,
          ...data,
        }));
        setError(undefined);
      }, setError),
    );
    socket.on(
      EVENT_OTHER_RESPONDENT_JOINED,
      handleSocketError((data: { connectedRespondents: number }) => {
        const connectedRespondents = data.connectedRespondents;
        setServerState(oldState => {
          const updatedState = {
            ...oldState!,
            connectedRespondents,
          };
          return updatedState;
        });
      }, setError),
    );
    socket.on(
      EVENT_LIVEFEED_COMMENT_RECEIVED,
      handleSocketError((comment: IComment) => {
        setServerState(oldState => {
          let oldLiveFeedQuestionResponse = {
            ...oldState!.liveFeedQuestionResponse!,
          };

          oldLiveFeedQuestionResponse.comments =
            oldLiveFeedQuestionResponse.comments || [];
          oldLiveFeedQuestionResponse.comments.push(comment);

          const updatedState = {
            ...oldState!,
            liveFeedQuestionResponse: oldLiveFeedQuestionResponse,
          };
          return updatedState;
        });
      }, setError),
    );

    socket.on(
      EVENT_LIVEPOLL_SESSION_FINISHED,
      handleSocketError(() => {
        setServerState(oldState => {
          const livePollSession = {
            ...oldState!.livePollSession!,
            state: LivePollSessionState.COMPLETED,
          };

          const updatedState = {
            ...oldState!,
            livePollSession,
          };

          socket.disconnect();

          return updatedState;
        });
      }, setError),
    );

    socket.on(
      EVENT_LIVEPOLL_SESSION_TERMINATED,
      handleSocketError(() => {
        setServerState(oldState => {
          const livePollSession = {
            ...oldState!.livePollSession!,
            state: LivePollSessionState.TERMINATED_USER,
          };
          const updatedState = {
            ...oldState!,
            livePollSession,
          };
          socket.disconnect();
          return updatedState;
        });
        removeSessionInfo();
      }, setError),
    );

    socket.on(
      EVENT_FINISH_QUESTION,
      handleSocketError((data: FinishQuestionEventData) => {
        setServerState(oldState => {
          const updatedServerState = {
            ...oldState!,
            livePollSession: { ...data.livePollSession },
            question: { ...data.question },
            startQuestionCountdown: undefined,
            wordCloudResponseCount: 0,
            teamLeaderboard: data.teamLeaderboard!,
          };

          if (data.respondentScores) {
            const myId = oldState?.respondent.id;
            const myResponse = data.respondentScores.find(
              response => response[0] === myId,
            );

            const score = myResponse ? myResponse[3] : 0;
            const rank = myResponse ? myResponse[1] : 0;

            if (rank > 0) {
              updatedServerState.rank = rank;
              updatedServerState.previousRank = oldState?.rank || 0;
            }

            updatedServerState.score = score;
            updatedServerState.leaderboard = data.respondentScores;
          }

          // We get correct answer details after question is finished
          if (
            updatedServerState.questionResponse &&
            typeof data.question.answers[0].isCorrect !== 'undefined'
          ) {
            const { answerId } =
              updatedServerState.questionResponse as ChoiceQuestionResponse;
            const correct = data.question.answers.some(
              a => a.id === answerId && a.isCorrect,
            );

            (
              updatedServerState.questionResponse as ChoiceQuestionResponse
            ).correct = correct;
          }

          if (updatedServerState.questionResponse) {
            (
              updatedServerState.questionResponse as ChoiceQuestionResponse
            ).correctAnswers = data.question.answers.filter(a => !!a.isCorrect);
          }

          if (data.wordCloudResponses) {
            updatedServerState.wordCloudResponses = [
              ...data.wordCloudResponses,
            ];
          }
          return updatedServerState;
        });
      }, setError),
    );

    socket.on(
      EVENT_RECEIVED_VOTES,
      handleSocketError((comment: IComment) => {
        setServerState(oldState => {
          let oldLiveFeedQuestionResponse = {
            ...oldState!.liveFeedQuestionResponse!,
          };
          const index = oldLiveFeedQuestionResponse.comments.findIndex(
            item => item.id === comment.id,
          );
          oldLiveFeedQuestionResponse.comments[index] = { ...comment };

          const updatedState = {
            ...oldState!,
            liveFeedQuestionResponse: oldLiveFeedQuestionResponse,
          };
          return updatedState;
        });
      }, setError),
    );

    return () => {
      socket.off(EVENT_START_QUESTION);
      socket.off(EVENT_START_QUESTION_COUNTDOWN);
      socket.off(EVENT_LIVEFEED_COMMENT_RECEIVED);
      socket.off(EVENT_LIVEPOLL_SESSION_FINISHED);
      socket.off(EVENT_LIVEPOLL_SESSION_TERMINATED);
      socket.off(EVENT_FINISH_QUESTION);
      socket.off(EVENT_RECEIVED_VOTES);
      socket.off(EVENT_OTHER_RESPONDENT_JOINED);
    };
  }, [socket]);

  const value = useMemo(
    () => ({
      loadingSession,
      serverState: serverState!,
      submitAnswer,
      submitComment,
      submitWordCloudAnswer,
      submitSwipeQuestion,
      submitCommentVotes,
      submitProfileQuestionAnswer,
      submitLastProfileQuestionAnswer,
      clientServerTimeDiffInMs,
      submitWordGameWord,
      changeRespondentTeam,
      getWordsForWordGame,
      submitMultiSelectAnswerIds,
      getQuestionForPermanentRoom,
      error,
    }),
    [
      loadingSession,
      serverState,
      submitAnswer,
      submitWordCloudAnswer,
      submitSwipeQuestion,
      submitComment,
      submitCommentVotes,
      submitProfileQuestionAnswer,
      submitLastProfileQuestionAnswer,
      clientServerTimeDiffInMs,
      submitWordGameWord,
      changeRespondentTeam,
      getWordsForWordGame,
      submitMultiSelectAnswerIds,
      getQuestionForPermanentRoom,
      error,
    ],
  );

  if (error && errorMessages.indexOf(error.message) === -1) {
    return (
      <>
        <div className={styles.socketDisconnectionMessage}>
          Oops, an error occurred ({error.message}).
          {refreshMessage}
        </div>
      </>
    );
  }

  if (loadingSession) {
    return <Spinner message="Loading..." />;
  }
  return (
    <TakeLivePollStateContext.Provider value={value}>
      {children}
    </TakeLivePollStateContext.Provider>
  );
};

export const useSubmitAnswer = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitAnswer must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitAnswer;
};

export const useSubmitMultiSelectAnswerIds = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitMultiSelectAnswerIds must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitMultiSelectAnswerIds;
};

export const useSubmitProfileQuestionAnswer = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitProfileQuestionAnswer must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitProfileQuestionAnswer;
};

export const useSubmitLastProfileQuestionAnswer = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitProfileQuestionAnswer must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitLastProfileQuestionAnswer;
};

export const useSubmitComment = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitComment must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitComment;
};

export const useSubmitWordCloudAnswer = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitWordCloudAnswer must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitWordCloudAnswer;
};

export const useSubmitSwipeQuestion = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitSwipeQuestion must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitSwipeQuestion;
};

export const useSubmitCommentVotes = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitCommentVotes must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitCommentVotes;
};

export const useTakeLivePollState = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useTakeLivePollState must be used within a TakeLivePollStateProvider`,
    );
  }
  return context;
};

export const useClientServerTimeDiffInMs = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useClientServerTimeDiffInMs must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.clientServerTimeDiffInMs;
};

export const useSubmitWordGameWord = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useSubmitWordGameWord must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.submitWordGameWord;
};

export const useError = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(`useError must be used within a TakeLivePollStateProvider`);
  }
  return context.error;
};

export const useChangeRespondentTeam = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useChangeRespondentTeam must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.changeRespondentTeam;
};

export const useGetWordGameWords = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useGetWordGameWords must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.getWordsForWordGame;
};

export const useGetQuestionForPermanentRoom = () => {
  const context = React.useContext(TakeLivePollStateContext);
  if (!context) {
    throw new Error(
      `useGetQuestionForPermanentRoom must be used within a TakeLivePollStateProvider`,
    );
  }
  return context.getQuestionForPermanentRoom;
};
