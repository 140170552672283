import * as React from 'react';
import { Button } from '../../../components/button/Button';
import { QuestionHeader } from '../../../components/currentQuestion/QuestionHeader';
import { useTakeLivePollState } from '../../../contexts/takeLivePollStateContext';
import { IWordCloudQuestion } from '../../../models/IWordCloudQuestion';
import { transformToStartCase } from '../../utils/stringUtils';
import styles from './WordCloudQuestionStarted.module.scss';

interface Props {
  question: IWordCloudQuestion;
  submitWordCloudAnswer(
    questionId: string,
    text: string,
    remainingResponses: number,
  ): void;
}

export const WordCloudQuestionStarted = ({
  question,
  submitWordCloudAnswer,
}: Props) => {
  const { serverState } = useTakeLivePollState();
  const [answer, setAnswer] = React.useState<string>('');
  const [submittingResponse, setSubmittingResponse] =
    React.useState<boolean>(false);
  const mountedRef = React.useRef<boolean>(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  const wordCloudResponseCount = serverState.wordCloudResponseCount || 0;

  const textChange = (event: any) => {
    const value = event.target.value as string;
    const currentLetter = event.nativeEvent.data as string;
    const trimmedAnswer = value.trimStart();

    // no blank space check
    if (!trimmedAnswer) {
      setAnswer('');
      return;
    }

    // no more than one space at the end of the sentence check
    if (answer[answer.length - 1] === ' ' && currentLetter === ' ') {
      setAnswer(prevAnswer => prevAnswer);
      return;
    }

    // transform to start case
    const startCaseSentence = transformToStartCase(trimmedAnswer);
    setAnswer(startCaseSentence);
  };

  const onSubmitClicked = async () => {
    if (!answer || remainingResponses === 0) {
      return;
    }
    setSubmittingResponse(true);
    await submitWordCloudAnswer(
      question.id,
      answer.trimEnd(),
      remainingResponses - 1,
    );
    if (mountedRef.current) {
      setAnswer('');
      setSubmittingResponse(false);
    }
  };

  const responseLimit = question.responseLimit!;
  const remainingResponses = responseLimit - wordCloudResponseCount;
  const disableSendBtn = submittingResponse || remainingResponses === 0;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <QuestionHeader question={question} />
          <div className={styles.answerContainer}>
            <div className={styles.textInputContainer}>
              <div className={styles.answerLimit}>
                Answer Limit: {remainingResponses}
              </div>
              <textarea
                name="answer"
                className={styles.textInput}
                placeholder={'Write your answer here....'}
                rows={1}
                maxLength={25}
                value={answer}
                onChange={textChange}
                data-testid="enterAnswerTextbox"
              />
              <div className={styles.textLength}>
                <span>{answer.length}/25</span>
              </div>
            </div>
            <div className={styles.btn} onClick={onSubmitClicked}>
              <Button label={'Send'} disable={disableSendBtn} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
