import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { Spinner } from '../components/spinner/Spinner';
import { WS_CONNECTION_URL } from '../constants/envConstants';
import { IsConnectedInAnotherTabState } from '../models/ProcessIsOpenInAnotherTabState.enum';
import { pageReload } from '../screens/utils/reloadUtil';
import styles from './allowOneSocketConnectionContext.module.css';

interface ContextValue {
  socket: Socket;
  connected: boolean;
  loadingSession: boolean;
}

interface Props {
  children: React.ReactNode;
}

const AllowOneSocketConnectionContext =
  React.createContext<ContextValue | null>(null);
const PROCESS_CONNECTED_IN_ANOTHER_TAB = 'connectedInAnotherTab';

const handleRefresh = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  pageReload();
};

const refreshMessage = (
  <span>
    Click{' '}
    <a href="/" onClick={handleRefresh} data-testid="reloadPage">
      here
    </a>{' '}
    to refresh the page
  </span>
);

export const AllowOneSocketConnectionProvider = ({ children }: Props) => {
  const { respondentId } = useParams<{ respondentId: string }>();
  const { livePollSessionId } = useParams<{ livePollSessionId: string }>();

  const [safeToLoad, setSafeToLoad] = React.useState<boolean>(false);
  const [connectionError, setConnectionError] = React.useState<
    Error | undefined
  >();
  const [connected, setConnected] = React.useState<boolean>(true);
  const [loadingSession, setLoadingSession] = React.useState<boolean>(true);

  const socketRef = React.useRef<Socket | null>(null);
  const processConnectedInAnotherTab = (timeoutId: NodeJS.Timeout) => {
    localStorage.setItem(
      PROCESS_CONNECTED_IN_ANOTHER_TAB,
      IsConnectedInAnotherTabState.CHECK,
    );

    window.onstorage = (storageEvent: StorageEvent) => {
      const updatedLocalStorageKey = storageEvent.key;
      if (updatedLocalStorageKey === PROCESS_CONNECTED_IN_ANOTHER_TAB) {
        if (storageEvent.newValue === IsConnectedInAnotherTabState.CHECK) {
          // is connected in another tab, disconnect
          localStorage.setItem(
            PROCESS_CONNECTED_IN_ANOTHER_TAB,
            IsConnectedInAnotherTabState.FOUND,
          );
          const socketDisconnectPromise = new Promise<void>(resolve => {
            socketRef.current?.on('disconnect', () => {
              resolve();
            });
          });
          socketRef.current?.disconnect();
          const timeoutPromise = new Promise<void>(resolve => {
            setTimeout(resolve, 10_000);
          });

          Promise.race([socketDisconnectPromise, timeoutPromise]).then(() => {
            window.location.replace('/enter-pin');
            localStorage.setItem(
              PROCESS_CONNECTED_IN_ANOTHER_TAB,
              IsConnectedInAnotherTabState.FINISH,
            );
          });
        } else if (
          storageEvent.newValue === IsConnectedInAnotherTabState.FOUND
        ) {
          // clear timeout, since, connected in another tab
          clearTimeout(timeoutId);
        } else if (
          storageEvent.newValue === IsConnectedInAnotherTabState.FINISH
        ) {
          // disconnected from another tab, now safe to connect from new tab
          setSafeToLoad(true);
          localStorage.removeItem(PROCESS_CONNECTED_IN_ANOTHER_TAB);
        }
      }
    };
  };

  React.useEffect(() => {
    // calculateClientServerTimeDiffInMs();
    const isconnectedInAnotherTabState = localStorage.getItem(
      PROCESS_CONNECTED_IN_ANOTHER_TAB,
    );

    // in case if no storage event is received, then join after the timeout expires
    const timeoutId = setTimeout(() => {
      setSafeToLoad(true);
      localStorage.removeItem(PROCESS_CONNECTED_IN_ANOTHER_TAB);
    }, 500);

    // if processConnectedInAnotherTab is not in progress
    if (!isconnectedInAnotherTabState) {
      // check if opened in another tab
      processConnectedInAnotherTab(timeoutId);
    } else {
      // if processConnectedInAnotherTab is in progress, wait till the process finishes
      // this is useful in scenarios where user is trying connect from multiple tabs at
      // the same time, for example via script
      clearTimeout(timeoutId);
      const intervalId = setInterval(() => {
        const isconnectedInAnotherTabState = localStorage.getItem(
          PROCESS_CONNECTED_IN_ANOTHER_TAB,
        );
        if (!isconnectedInAnotherTabState) {
          clearInterval(intervalId);
          // check if opened in another tab
          processConnectedInAnotherTab(timeoutId);
        }
      }, 1000);
    }
    return () => {
      // setConnected(false);
      socketRef.current?.disconnect();
      window.onstorage = null;
      clearTimeout(timeoutId);
    };
  }, []);

  if (!socketRef.current && safeToLoad) {
    const socket = io(WS_CONNECTION_URL, {
      path: '/api/respondent',
      auth: {
        respondentId,
        livePollSessionId,
      },
      withCredentials: true,
    });
    socketRef.current = socket;

    socket.on('connect', () => {
      // On connect/reconnect sync the LivePollSession again
      setConnected(true);
      setConnectionError(undefined);
      setLoadingSession(false);
    });

    socket.on('disconnect', reason => {
      console.log('socket disconnected: ', reason);
      setConnected(false);
    });

    socket.on('connect_error', error => {
      setConnectionError(error);
    });

    socket.on('close', () => {
      setLoadingSession(true);
      setConnected(false);
      socket.connect();
    });
  }

  const value = useMemo(
    () => ({
      socket: socketRef.current!,
      connected,
      loadingSession,
    }),
    [connected, loadingSession],
  );

  if (connectionError) {
    return (
      <>
        <div className={styles.socketDisconnectionMessage}>
          Oops, connection error ({connectionError.message}).
          {refreshMessage}
        </div>
      </>
    );
  }

  if (loadingSession) {
    return <Spinner message="Loading..." />;
  }

  return (
    <AllowOneSocketConnectionContext.Provider value={value}>
      {children}
    </AllowOneSocketConnectionContext.Provider>
  );
};

export const useAllowOneSocketConnection = () => {
  const context = React.useContext(AllowOneSocketConnectionContext);
  if (!context) {
    throw new Error(
      `useAllowOneSocketConnection must be used within AllowOneSocketConnectionContext`,
    );
  }

  return context;
};
