import styles from './WordPuzzle.module.css';
import React from 'react';

import {
  useGetWordGameWords,
  useSubmitWordGameWord,
  useTakeLivePollState,
} from '../../contexts/takeLivePollStateContext';
import { WordGamePartialLeaderBoard } from './components/WordGamePartialLeaderBoard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import {
  addSelectedLetterStyles,
  getRandomWord,
  leftDistanceInPixels,
  removeSelectedLetterStyles,
  resetTilePosition,
  shiftLetterTiles,
} from './utils/WordPuzzle.utils';
import { shuffleWord } from './utils/WordPuzzle.utils';
import { DifficultyLevel } from './models/difficulty-level.enum';
import { Spinner } from '../../components/spinner/Spinner';
import { getBrowserLangs } from '../utils/browserLangUtil';

const MIN_WORD_COUNT_FOR_MEDIUM_DIFFICULTY = 50;
const MIN_WORD_COUNT_FOR_HARD_DIFFICULTY = 100;

interface Props {
  closeWordPuzzle: () => void;
}
export const WordPuzzle = ({ closeWordPuzzle }: Props) => {
  const submitWordGameWord = useSubmitWordGameWord();
  const getWordsForWordGameByLevel = useGetWordGameWords();
  const { serverState } = useTakeLivePollState();
  const correctAnswerCount = serverState.wordCount!;
  const [difficultyLevel, setDifficultyLevel] = React.useState<number | null>(
    null,
  );
  const [words, setWords] = React.useState<string[]>([]);
  const [word, setWord] = React.useState<string>('');

  const [lastCorrectWord, setLastCorrectWord] = React.useState<string>('');
  const [loadingWords, setLoadingWords] = React.useState<boolean>(true);
  const [showLoadingNextPuzzleScreen, setShowLoadingNextPuzzleScreen] =
    React.useState<boolean>(false);
  const [showInstructions, setShowInstructions] = React.useState<boolean>(true);

  const [shuffleWordArray, setShuffleWordArray] = React.useState<string[]>([]);
  const [selectedLetterId, setSelectedLetterId] = React.useState<
    string | undefined
  >();
  const [error, setError] = React.useState<Error | null>(null);

  const loadNextWord = React.useCallback(() => {
    if (!difficultyLevel) {
      return;
    }

    const word = getRandomWord(words, difficultyLevel);
    setWord(word);
    const shuffleWordString = shuffleWord(word.toUpperCase());
    setShuffleWordArray(shuffleWordString.split(''));
  }, [difficultyLevel, words]);

  React.useEffect(() => {
    setDifficultyLevel(previousDifficultyLevel => {
      if (
        correctAnswerCount < MIN_WORD_COUNT_FOR_MEDIUM_DIFFICULTY &&
        previousDifficultyLevel !== DifficultyLevel.EASY
      ) {
        return DifficultyLevel.EASY;
      } else if (
        correctAnswerCount >= MIN_WORD_COUNT_FOR_HARD_DIFFICULTY &&
        previousDifficultyLevel !== DifficultyLevel.HARD
      ) {
        return DifficultyLevel.HARD;
      } else if (
        correctAnswerCount >= MIN_WORD_COUNT_FOR_MEDIUM_DIFFICULTY &&
        previousDifficultyLevel !== DifficultyLevel.MEDIUM
      ) {
        return DifficultyLevel.MEDIUM;
      } else {
        return previousDifficultyLevel;
      }
    });
  }, [correctAnswerCount]);

  React.useEffect(() => {
    (async () => {
      if (!difficultyLevel) {
        return;
      }
      try {
        setLoadingWords(true);
        const browserLangs = getBrowserLangs();
        const { words } = await getWordsForWordGameByLevel(
          difficultyLevel,
          browserLangs,
        );
        if (!words || words.length === 0) {
          setError(new Error('Cannot get word list from server'));
          return;
        }

        setWords(words);
        setWord(getRandomWord(words, difficultyLevel));
      } catch (err: any) {
        setError(err);
      } finally {
        setLoadingWords(false);
      }
    })();
  }, [difficultyLevel, getWordsForWordGameByLevel]);

  React.useEffect(() => {
    if (word && shuffleWordArray.length === 0) {
      const shuffledWord = shuffleWord(word);
      const upperCasedWordArr = shuffledWord
        .split('')
        .map(sw => sw.toUpperCase());
      setShuffleWordArray(upperCasedWordArr);
    }
  }, [shuffleWordArray, word]);

  React.useEffect(() => {
    if (!word) {
      return;
    }

    const wordArray = word.split('').map(w => w.toUpperCase());
    const userArrangedWord = shuffleWordArray;

    if (JSON.stringify(wordArray) === JSON.stringify(userArrangedWord)) {
      //If Both string are same then load next string
      setLastCorrectWord(word);
      loadNextWord();
      submitWordGameWord();
      setShowLoadingNextPuzzleScreen(true);
    }
  }, [
    shuffleWordArray,
    word,
    setShuffleWordArray,
    loadNextWord,
    submitWordGameWord,
  ]);

  const swap = (previous: string, current: string) => {
    const fromIndex = parseInt(previous.substr(1, 1));
    const toIndex = parseInt(current.substr(1, 1));
    const tiles = [...shuffleWordArray];

    if (fromIndex !== -1 && toIndex !== -1) {
      let temp = tiles[fromIndex];
      tiles[fromIndex] = tiles[toIndex];
      tiles[toIndex] = temp;
      setShuffleWordArray(tiles);
    }
  };

  const swapLetters = (previousLetterId: string, currentLetterId: string) => {
    if (previousLetterId && currentLetterId) {
      removeSelectedLetterStyles(previousLetterId);
      shiftLetterTiles(previousLetterId, currentLetterId);
      setSelectedLetterId(undefined);
      setTimeout(() => {
        resetTilePosition();
        swap(previousLetterId, currentLetterId);
      }, 200);
    }
  };

  const handleOnClick = (elementId: string) => {
    if (elementId === selectedLetterId) {
      removeSelectedLetterStyles(elementId);
      setSelectedLetterId(undefined);
      return;
    }

    if (!selectedLetterId) {
      addSelectedLetterStyles(elementId);
      setSelectedLetterId(elementId);
    } else {
      swapLetters(selectedLetterId, elementId);
      setShowInstructions(false);
    }
  };

  const makeBoxes = () => {
    return (
      <div className={styles.letterTilesContainer}>
        {shuffleWordArray.map((letter, index) => (
          <div
            data-testid="letter"
            key={letter + index}
            id={letter + index}
            className={`${styles.letterTile} ${
              letter === word[index] ? styles.correctTile : ''
            }
              `}
            style={{ left: leftDistanceInPixels(index) }}
            onClick={() => handleOnClick(letter + index)}
            attr-tile-selector=""
          >
            {letter}
          </div>
        ))}
      </div>
    );
  };

  if (showLoadingNextPuzzleScreen) {
    setTimeout(() => {
      setShowLoadingNextPuzzleScreen(false);
    }, 4000);
  }

  if (error) {
    return (
      <div className={styles.container}>
        Oops an error occurred ({error.message})
      </div>
    );
  }

  if (loadingWords) {
    return (
      <div className={styles.container}>
        <div className={styles.title}>Word Puzzle</div>
        <Spinner message="Setting game difficulty" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div onClick={closeWordPuzzle} className={styles.backButton}>
        <FontAwesomeIcon icon={faAngleLeft} />
        <span>Back</span>
      </div>
      <div className={styles.title}>Word puzzle</div>
      {!showLoadingNextPuzzleScreen ? (
        <>
          <div className={styles.correctWordCount} data-testid="guessCount">
            {correctAnswerCount > 0 && <>Correct: {correctAnswerCount}</>}
          </div>

          {makeBoxes()}
          <div className={styles.skipButton} onClick={loadNextWord}>
            Skip
          </div>
        </>
      ) : (
        <div className={styles.correctMessage}>
          <div className={styles.lastCorrectWord}>"{lastCorrectWord}"</div>
          <div>Congratulations! Your Guess is correct.</div>
          <div>Prepare For Next Word.</div>
          <WordGamePartialLeaderBoard />
        </div>
      )}
      {!showLoadingNextPuzzleScreen && (
        <div
          className={
            showInstructions ? styles.tipContainer : styles.instructions
          }
        >
          <div
            className={styles.tip}
            data-testid={
              showInstructions ? 'instruction1' : 'hiddenInstruction1'
            }
          >
            <span style={{ marginRight: '5px' }}>
              <FontAwesomeIcon
                icon={faLightbulb}
                className={styles.lightbulb}
              />
            </span>
            <strong>Click on letters </strong> to swap.
          </div>
          <div
            className={styles.tip}
            data-testid={
              showInstructions ? 'instruction2' : 'hiddenInstruction2'
            }
          >
            <span style={{ marginRight: '5px' }}>
              <FontAwesomeIcon
                icon={faLightbulb}
                className={styles.lightbulb}
              />
            </span>
            A
            <span style={{ color: 'green', fontWeight: 'bolder' }}>
              {' '}
              green{' '}
            </span>
            tile will indicate a letter is in the right spot.
          </div>
        </div>
      )}
    </div>
  );
};
