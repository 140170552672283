import { Route, Routes, useNavigate } from 'react-router-dom';
import styles from './App.module.scss';
import { AllowOneSocketConnectionProvider } from './contexts/allowOneSocketConnectionContext';
import { TakeLivePollStateProvider } from './contexts/takeLivePollStateContext';
import { IRespondent } from './models/Respondent';
import { PermanentRoomScreen } from './PermanentRoomScreen';
import { EnterPinScreen } from './screens/enter-pin/EnterPinScreen';
import { LivePollFinishedScreen } from './screens/livepoll-finished/LivePollFinishedScreen';
import { EnterNamePermanentRoomScreen } from './screens/enter-permanent-room/EnterNamePermanentRoomScreen';
import { TakeLivePoll } from './TakeLivePoll';
import { SHOW_SWIPE_TUTORIAL_KEY } from './constants/local-storage-keys.constants';

function App() {
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(window.location.search);
  const pinWithUrl = useQuery().get('pin');

  const handleRespondentJoined = (respondent: IRespondent) => {
    localStorage.removeItem(SHOW_SWIPE_TUTORIAL_KEY);
    navigate(`/take/${respondent.livePollSessionId}/${respondent.uid}`);
  };

  return (
    <div className={styles.mainContainer}>
      <Routes>
        <Route
          path="/enter-pin"
          element={
            <EnterPinScreen
              onRespondentJoined={handleRespondentJoined}
              pinWithUrl={pinWithUrl}
            />
          }
        ></Route>

        <Route
          path="/take/:livePollSessionId/:respondentUid/finish-session"
          element={<LivePollFinishedScreen />}
        ></Route>

        <Route
          path="/take/:livePollSessionId/:respondentId"
          element={
            <AllowOneSocketConnectionProvider>
              <TakeLivePollStateProvider>
                <TakeLivePoll />
              </TakeLivePollStateProvider>
            </AllowOneSocketConnectionProvider>
          }
        ></Route>

        <Route
          path="/room/:roomId"
          element={<EnterNamePermanentRoomScreen />}
        ></Route>

        <Route
          path="/room/:livePollSessionId/:respondentId"
          element={
            <AllowOneSocketConnectionProvider>
              <TakeLivePollStateProvider>
                <PermanentRoomScreen />
              </TakeLivePollStateProvider>
            </AllowOneSocketConnectionProvider>
          }
        ></Route>
        <Route
          path="/room/:livePollSessionId/:respondentUid/finish-session"
          element={<LivePollFinishedScreen />}
        ></Route>

        <Route
          path="/"
          element={
            <EnterPinScreen
              onRespondentJoined={handleRespondentJoined}
              pinWithUrl={pinWithUrl}
            />
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
