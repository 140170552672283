import * as React from 'react';
import {
  useChangeRespondentTeam,
  useTakeLivePollState,
} from '../../contexts/takeLivePollStateContext';
import { QuestionStartedCountDownScreen } from '../question-started-countdown/QuestionStartedCountDownScreen';
import { Trivia } from './components/Trivia';
import { WelcomeMessage } from './components/WelcomeMessage';
import { StartProfileQuestions } from './components/StartProfileQuestionScreen';
import styles from './WaitingRoomScreen.module.scss';
import { ProfileQuestionsFinishedScreen } from '../profile-question-finished/ProfileQuestionsFinishedScreen';
import { Button } from '../../components/button/Button';
import { WordPuzzle } from '../games/WordPuzzle';
import { SelectedTeamDetails } from './components/SelectedTeamDetails';
import { TeamSelectionDropDown } from './components/TeamSelectionDropDown';
import { Team } from '../../models/Team';

interface Props {
  title: string;
  isProfileQuestionsFinishedScreenDisplayed?: boolean;
}
const isProfileQuestionsAnswered = (
  profileQuestionsAnsweredCount: number | undefined,
  profileQuestionsLength: number | undefined,
) => {
  if (
    profileQuestionsLength == null ||
    profileQuestionsAnsweredCount === profileQuestionsLength
  ) {
    return false;
  }
  return true;
};

const isLoadProfileQuestionsFinishedScreen = (
  profileQuestionsAnsweredCount: number | undefined,
  profileQuestionsLength: number | undefined,
  isProfileQuestionsFinishedScreenDisplayed: boolean | undefined,
) => {
  return (
    profileQuestionsAnsweredCount &&
    profileQuestionsAnsweredCount > 0 &&
    profileQuestionsAnsweredCount === profileQuestionsLength &&
    !isProfileQuestionsFinishedScreenDisplayed
  );
};

export const WaitingRoomScreen = ({
  title,
  isProfileQuestionsFinishedScreenDisplayed,
}: Props) => {
  const { serverState } = useTakeLivePollState();
  const { respondent } = serverState;
  const showTeam = !!respondent.teamUid;
  const isAnonymous =
    !!serverState.livePollSession?.setting?.anonymousResponses;
  const question = serverState.startQuestionCountdown;
  const profileQuestions = serverState.profileQuestions;
  const [
    isProfileQuestionsAvailableToAnswer,
    setIsProfileQuestionsAvailableToAnswer,
  ] = React.useState<boolean>(false);

  const [playGame, showPlayGame] = React.useState<boolean>(false);
  const changeTeam = useChangeRespondentTeam();

  React.useEffect(() => {
    setIsProfileQuestionsAvailableToAnswer(
      isProfileQuestionsAnswered(
        serverState.profileQuestionsAnsweredCount,
        profileQuestions?.length,
      ),
    );
  }, [profileQuestions?.length, serverState.profileQuestionsAnsweredCount]);

  const loadProfileQuestionsFinishedScreen =
    isLoadProfileQuestionsFinishedScreen(
      serverState.profileQuestionsAnsweredCount,
      profileQuestions?.length,
      isProfileQuestionsFinishedScreenDisplayed,
    );

  if (question) {
    return <QuestionStartedCountDownScreen question={question} />;
  }

  if (
    serverState.livePollSession?.setting?.enableProfileQuestions &&
    isProfileQuestionsAvailableToAnswer
  ) {
    return <StartProfileQuestions title={title} />;
  }

  if (loadProfileQuestionsFinishedScreen) {
    return <ProfileQuestionsFinishedScreen />;
  }

  const loadGameScreen = () => {
    showPlayGame(true);
  };

  const closeWordPuzzle = () => {
    showPlayGame(false);
  };

  const handleChangeRespondentTeam = (team: Team) => {
    const teamUid = team.id!;
    changeTeam(teamUid);
  };

  if (playGame) {
    return <WordPuzzle closeWordPuzzle={() => closeWordPuzzle()} />;
  }

  return (
    <>
      <WelcomeMessage title={title} />
      <div className={styles.showTeam}>
        {showTeam && <SelectedTeamDetails />}
        {!isAnonymous && (
          <TeamSelectionDropDown
            changeRespondentTeam={team => handleChangeRespondentTeam(team)}
            text="Change team"
          />
        )}
      </div>
      <Trivia />

      <div className={styles.waitMessage}>
        Please wait, we're about to start...
        <div className={styles.games}>
          <div className={styles.playGameMessage}>
            Meanwhile you can play some interesting games.
          </div>
          <div onClick={loadGameScreen}>
            <Button label="Play Game" />
          </div>
        </div>
      </div>
    </>
  );
};
