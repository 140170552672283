import * as React from 'react';
import { InputName } from './components/InputName';
import { InputPin } from './components/InputPin';
import styles from './EnterPinScreen.module.scss';
import livePollLogo from '../../images/livePoll_logo.svg';
import { Button } from '../../components/button/Button';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { IRespondent } from '../../models/Respondent';
import { ILivePollSession } from '../../models/LivePollSession';
import { getApi, postApi } from '../utils/apiUtil';
import { redirectTo } from '../utils/redirectUtil';
import { IProfileQuestion } from '../../models/ProfileQuestion';

const NICK_NAME_LOCAL_STORAGE = 'lpNickName';
export const NAME_MAX_LENGTH = 16;

interface Props {
  onRespondentJoined(
    respondent: IRespondent,
    livePollSession: ILivePollSession,
    profileQuestions: IProfileQuestion[],
    teamId: number,
  ): void;

  pinWithUrl: string | null;
}
interface SessionInfo {
  pin: string;
  respondentId: string;
  livePollSessionId: number;
}

export const EnterPinScreen = ({ pinWithUrl, onRespondentJoined }: Props) => {
  const [storedName, setStoredName] = useLocalStorage(
    NICK_NAME_LOCAL_STORAGE,
    false,
    '',
  );
  const [sessionName, setSessionName] = React.useState<string | undefined>();
  const [name, setName] = React.useState<string>(storedName || '');
  const [pin, setPin] = React.useState<string[]>(['', '', '', '']);
  const [isAnonymous, setAnonymous] = React.useState<undefined | boolean>();
  const [isVerifyingPin, setVerifyingPin] = React.useState<boolean>(false);
  const unmountedRef = React.useRef<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  /* Auto-submit form when:
    1. Name is auto-populated from local storage on page load.
    2. Name hasn't been modified 
    3. and isAnonymous is false 
    */
  const [autoSubmitForm, setAutoSubmitForm] = React.useState<boolean>(
    !!storedName && storedName.length > 3,
  );

  React.useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const submitForm = React.useCallback(async () => {
    setVerifyingPin(true);
    try {
      const { data } = await postApi(`/take/pin`, {
        pin: pin.join(''),
        name,
      });
      onRespondentJoined(
        data.respondent,
        data.livePollSession,
        data.profileQuestions,
        data.teamId,
      );
    } catch (error: any) {
      if (error?.response?.status !== 404) {
        console.error(error);
      }
      setErrorMessage(error?.response?.data?.message);
      setVerifyingPin(false);
    }
  }, [name, pin, onRespondentJoined]);

  React.useEffect(() => {
    const hasAllDigits = pin.filter(p => p === '').length === 0;

    if (hasAllDigits) {
      // When we have all four digit of the pin then we check in localstorage.
      // whether pin is present with the respondent id.
      // if we found respondent id from local storage then redirect user to takeLivePoll.
      let pinInNumber = pin.toString();
      pinInNumber = pinInNumber.replaceAll(`,`, ``);

      const sessionInfo = localStorage.getItem('sessionInfo')!;

      if (sessionInfo !== null) {
        const {
          pin: storedPin,
          respondentId,
          livePollSessionId,
        }: SessionInfo = JSON.parse(sessionInfo);

        if (pinInNumber === storedPin.toString()) {
          if (respondentId && livePollSessionId) {
            const takeUrl = `/take/${livePollSessionId}/${respondentId}`;
            redirectTo(takeUrl);
          }
        }
      }

      if (isAnonymous && !isVerifyingPin) {
        submitForm();
      }
      if (!sessionName || isAnonymous === undefined) {
        focusOnInputName();
        fetchSessionName(pin.join(''));
      }
    }
  }, [
    pin,
    name,
    submitForm,
    autoSubmitForm,
    sessionName,
    isAnonymous,
    isVerifyingPin,
    pinWithUrl,
  ]);

  React.useEffect(() => {
    if (pinWithUrl) {
      const pinInput: string[] = [
        pinWithUrl.charAt(0),
        pinWithUrl.charAt(1),
        pinWithUrl.charAt(2),
        pinWithUrl.charAt(3),
      ];
      setPin(pinInput);
    }
  }, [pinWithUrl]);

  const fetchSessionName = async (pin: string) => {
    // check the component is not unmounted before updating state.
    try {
      const { data } = await getApi(`/take/pin/${pin}`);
      if (!unmountedRef.current) {
        data.name && setSessionName(data.name);
        setAnonymous(data.anonymousResponses);
      }
    } catch (error: any) {
      if (error?.response.status !== 404) {
        console.error(error);
      }
    }
  };

  const renderPinLabel = () => {
    if (!!sessionName && sessionName !== 'Untitled') {
      return <span className={styles.sessionName}>{sessionName}</span>;
    }

    return (
      <>
        Enter <strong>PIN</strong>
      </>
    );
  };

  const handlePinChange = (newPin: string[]) => {
    setPin(newPin);
    setSessionName(undefined);
    setErrorMessage(null);
  };

  const focusOnInputName = () => {
    const inputName = document.getElementsByClassName(
      'fadeIn second',
    ) as HTMLCollectionOf<HTMLInputElement>;
    if (inputName != null && inputName.length > 0) {
      inputName[0].focus();
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /* Cancel auto submit. Basically for this scenario, user clear the name input,
    then enters the pin. */
    setAutoSubmitForm(false);
    setName(event.target.value);
    setStoredName(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    submitForm();
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <img alt="LivePoll Logo" src={livePollLogo} />
      <div className={styles.enterPinLabel}>{renderPinLabel()}</div>

      <div className={errorMessage ? styles.shakeInputPin : ''}>
        <InputPin pin={pin} onChange={handlePinChange} />
      </div>
      <div className={styles.errorMsgContainer}>
        {errorMessage && (
          <div className={styles.errorMsgTextColor}>{errorMessage}</div>
        )}
      </div>

      <InputName text={name} onChange={handleNameChange} />

      <div className={styles.submitBtnContainer}>
        <Button label="Join" disable={isVerifyingPin} />
      </div>
    </form>
  );
};
