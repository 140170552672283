import * as React from 'react';
import {
  useClientServerTimeDiffInMs,
  useError,
  useSubmitAnswer,
  useSubmitComment,
  useSubmitMultiSelectAnswerIds,
  useSubmitSwipeQuestion,
  useSubmitWordCloudAnswer,
  useTakeLivePollState,
} from '../../contexts/takeLivePollStateContext';
import { IAnswer } from '../../models/Answer';
import { ILivePollSession } from '../../models/LivePollSession';
import { LivePollSessionState } from '../../models/LivePollSessionState';
import { Answers } from './components/Answers';
import styles from './QuestionStartedScreen.module.scss';
import { Spinner } from '../../components/spinner/Spinner';
import { LiveFeedQuestionStarted } from './components/LiveFeedQuestionStarted';
import { QuestionType } from '../../models/QuestionType';
import { IChoiceQuestion } from '../../models/ChoiceQuestion';
import { QuestionStartedCountDownScreen } from '../question-started-countdown/QuestionStartedCountDownScreen';
import {
  calculateStartDelayInMs,
  calculateTimeRemainingInMs,
} from '../utils/timeUtil';
import { IQuestion } from '../../models/Question';
import { pageReload } from '../utils/reloadUtil';
import { LoadImage } from '../../components/load-image/LoadImage';
import { Airplane } from '../../components/animation/airplane/Airplane';
import { WordCloudQuestionStarted } from './components/WordCloudQuestionStarted';
import { IWordCloudQuestion } from '../../models/IWordCloudQuestion';
import { MultiSelectQuestionStarted } from './components/MultiSelectQuestionStarted';
import { ChoiceQuestionResponse } from '../../models/ChoiceQuestionResponse';
import { MultiSelectQuestionResponse } from '../../models/MultiSelectQuestionResponse';
import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
import { LivePollSessionType } from '../../models/LivePollSessionType';
import { SwipeQuestionStarted } from './components/SwipeQuestionStarted';
import { ISwipeQuestion } from '../../models/SwipeQuestion';
import { SwipeQuestionOptionSide } from '../../models/SwipeQuestionOptionSide.enum';

interface Props {
  livePollSession: ILivePollSession;
}

const isInvalidLivePollState = (state: LivePollSessionState) => {
  return !(
    state === LivePollSessionState.QUESTION_STARTED ||
    state === LivePollSessionState.QUESTION_FINISHED
  );
};

const handleRefresh = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  pageReload();
};

const refreshMessage = (
  <span className={styles.refreshMessage}>
    Click
    <a
      href="/"
      onClick={handleRefresh}
      data-testid="reloadPage"
      className={styles.hereLink}
    >
      here
    </a>
    to refresh the page
  </span>
);

export const QuestionStartedScreen = ({ livePollSession }: Props) => {
  const { serverState } = useTakeLivePollState();
  const { questionResponseTimer } = serverState;
  const [submittingAnswer, setSubmittingAnswer] =
    React.useState<boolean>(false);
  const submitAnswer = useSubmitAnswer();
  const submitMultiSelectAnswerIds = useSubmitMultiSelectAnswerIds();
  const submitWordCloudAnswer = useSubmitWordCloudAnswer();
  const submitSwipeQuestion = useSubmitSwipeQuestion();
  const submitComment = useSubmitComment();

  const clientServerTimeDiffInMs = useClientServerTimeDiffInMs();

  const questionTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const questionStartTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [, updateState] = React.useState<number>(1);
  const question = serverState.startQuestionCountdown;
  const initialStartDelayValue =
    calculateStartDelayInMs({
      questionResponseTimer,
      clientServerTimeDiffInMs,
    }) > 0;
  const [isStartDelay, setStartDelay] = React.useState<boolean>(
    initialStartDelayValue,
  );
  const error = useError();

  const handleSubmitAnswer = (answer: IAnswer): (() => Promise<void>) => {
    return async () => {
      setSubmittingAnswer(true);
      await submitAnswer(
        LivePollSessionType.PRESENTER_SESSION,
        currentQuestion.id,
        answer.id,
      );
      setSubmittingAnswer(false);
    };
  };

  const handleSubmitMultiSelectAnswer = (
    answerIds: string[],
  ): (() => Promise<void>) => {
    return async () => {
      setSubmittingAnswer(true);
      await submitMultiSelectAnswerIds(
        LivePollSessionType.PRESENTER_SESSION,
        currentQuestion.id,
        answerIds,
      );
      setSubmittingAnswer(false);
    };
  };

  const handleSubmitWordCloudAnswers = async (
    questionId: string,
    text: string,
  ) => {
    await submitWordCloudAnswer(
      LivePollSessionType.PRESENTER_SESSION,
      questionId,
      text,
    );
  };

  const submitLiveFeedComment = async (questionId: string, text: string) => {
    setSubmittingAnswer(true);
    await submitComment(
      LivePollSessionType.PRESENTER_SESSION,
      questionId,
      text,
    );
    setSubmittingAnswer(false);
  };

  const handleSubmitSwipeQuestion = async (
    questionId: string,
    cardId: string,
    selectedSide: SwipeQuestionOptionSide,
  ) => {
    await submitSwipeQuestion(
      LivePollSessionType.PRESENTER_SESSION,
      questionId,
      cardId,
      selectedSide,
    );
  };

  React.useEffect(() => {
    const timeRemaining = calculateTimeRemainingInMs({
      questionResponseTimer,
      clientServerTimeDiffInMs,
    });
    if (timeRemaining > 0) {
      questionTimeoutRef.current = setTimeout(() => {
        updateState(Math.random());
      }, timeRemaining);
    }

    const startDelay = calculateStartDelayInMs({
      questionResponseTimer,
      clientServerTimeDiffInMs,
    });
    if (startDelay > 0) {
      questionStartTimeoutRef.current = setTimeout(() => {
        setStartDelay(false);
      }, startDelay);
    }

    return () => {
      questionTimeoutRef.current && clearTimeout(questionTimeoutRef.current);
      questionStartTimeoutRef.current &&
        clearTimeout(questionStartTimeoutRef.current);
    };
  }, [questionResponseTimer, clientServerTimeDiffInMs]);

  if (!serverState.question) {
    return (
      <div>Oops an error occurred (currentQuestion cannot be undefined).</div>
    );
  }

  const hasQuestionResponse = serverState.questionResponse != null;
  const currentQuestion = serverState.question;

  let content = (
    <div className={styles.waitingMessage}>Waiting for next question</div>
  );

  const wordCloudQuestionResponseCount = serverState.wordCloudResponseCount
    ? serverState.wordCloudResponseCount
    : 0;

  const isWordCloudQuestionAnswered =
    currentQuestion.type === QuestionType.WORD_CLOUD &&
    wordCloudQuestionResponseCount ===
      (currentQuestion as IWordCloudQuestion).responseLimit;

  const isLoadingResponse = !hasQuestionResponse && submittingAnswer;
  const isNotYetAnswered = !hasQuestionResponse || !isWordCloudQuestionAnswered;
  const displayDifficultyLevel =
    !!livePollSession.setting?.displayDifficultyLevels;
  const questionDifficultyLevel =
    livePollSession.setting?.enableQuestionDifficulty &&
    currentQuestion.difficultyLevel != null
      ? currentQuestion.difficultyLevel
      : QuestionDifficultyLevel.NORMAL;

  if (isNotYetAnswered) {
    if (currentQuestion.type === QuestionType.TEXT) {
      content = (
        <LiveFeedQuestionStarted
          question={currentQuestion as IQuestion}
          submitLiveFeedComment={submitLiveFeedComment}
        />
      );
    } else if (currentQuestion.type === QuestionType.CHOICE) {
      if (currentQuestion.isMultiSelect) {
        content = (
          <MultiSelectQuestionStarted
            question={currentQuestion as IChoiceQuestion}
            onAnswerSelected={handleSubmitMultiSelectAnswer}
            questionDifficultyLevel={
              displayDifficultyLevel ? questionDifficultyLevel : null
            }
          />
        );
      } else {
        content = (
          <Answers
            question={currentQuestion as IChoiceQuestion}
            onAnswerSelected={handleSubmitAnswer}
            questionDifficultyLevel={
              displayDifficultyLevel ? questionDifficultyLevel : null
            }
          />
        );
      }
    } else if (currentQuestion.type === QuestionType.WORD_CLOUD) {
      content = (
        <WordCloudQuestionStarted
          question={currentQuestion as IWordCloudQuestion}
          submitWordCloudAnswer={handleSubmitWordCloudAnswers}
        />
      );
    } else if (currentQuestion.type === QuestionType.SWIPE) {
      content = (
        <SwipeQuestionStarted
          question={currentQuestion as ISwipeQuestion}
          submitSwipeAnswer={handleSubmitSwipeQuestion}
        />
      );
    }
  }

  if (error) {
    content = (
      <div className={styles.errorMessage} data-testid="errorMsg">
        {error.message}
        {refreshMessage}
      </div>
    );
  }

  if (isLoadingResponse && !error) {
    content = <Spinner message="Loading Response..." />;
  }

  const isTimeUp =
    calculateTimeRemainingInMs({
      questionResponseTimer,
      clientServerTimeDiffInMs,
    }) <= 0 || serverState.questionResponse?.timeUp;

  if (isTimeUp && currentQuestion.type === QuestionType.CHOICE) {
    content = (
      <>
        <Spinner message="Loading" />
      </>
    );
  }

  const isAnsweredInTime =
    (hasQuestionResponse && !serverState.questionResponse!.timeUp) ||
    isWordCloudQuestionAnswered;
  if (isAnsweredInTime) {
    const isMultiSelect = !!currentQuestion.isMultiSelect;
    const answerId = (serverState.questionResponse as ChoiceQuestionResponse)
      ?.answerId;

    if (isMultiSelect) {
      const answerIds = (
        serverState.questionResponse as MultiSelectQuestionResponse
      )?.answerIds;

      const msg =
        answerIds.length > 1
          ? 'Your answers were sent!'
          : 'Your answer was sent!';

      content = (
        <>
          <div className={styles.multiselectContainer}>
            <span className={styles.votedImage} data-testid="submittedAnswer">
              <Airplane />
            </span>

            {answerIds.map(ansId => {
              return (
                <div className={styles.answerSubmittedMessage} key={ansId}>
                  {(currentQuestion as IChoiceQuestion).answers.find(
                    (que: IAnswer) => que.id === ansId,
                  )?.image?.url ? (
                    <div>
                      <div>
                        {
                          (currentQuestion as IChoiceQuestion).answers.find(
                            (que: IAnswer) => que.id === ansId,
                          )?.text
                        }
                      </div>
                      <LoadImage
                        imageUrl={
                          (currentQuestion as IChoiceQuestion).answers.find(
                            (que: IAnswer) => que.id === ansId,
                          )!.image!.url
                        }
                        imgAltText="answer"
                        isOption={true}
                      />
                    </div>
                  ) : (
                    (currentQuestion as IChoiceQuestion).answers.find(
                      (que: IAnswer) => que.id === ansId,
                    )?.text
                  )}
                </div>
              );
            })}

            <div className={styles.message}>{msg}</div>
          </div>
        </>
      );
    } else {
      let selectedAnswer;
      if (answerId) {
        let question = currentQuestion as IChoiceQuestion;
        selectedAnswer = question.answers.find(a => a.id === answerId);
      }

      content = (
        <div className={styles.answerSubmittedContainer}>
          <span className={styles.votedImage} data-testid="submittedAnswer">
            <Airplane />
          </span>

          {selectedAnswer && !selectedAnswer?.image?.url && (
            <div className={styles.answerSubmittedMessage}>
              {selectedAnswer?.text}
            </div>
          )}
          {selectedAnswer && selectedAnswer?.image?.url && (
            <div className={styles.selectedAnswerContainer}>
              <div className={styles.innerSelectedAnswerContainer}>
                <div className={styles.answerText}>{selectedAnswer.text}</div>
                {selectedAnswer.image?.url && (
                  <LoadImage
                    imageUrl={selectedAnswer.image?.url}
                    imgAltText="answer"
                    isOption={true}
                  />
                )}
              </div>
            </div>
          )}

          <div className={styles.message}>Your answer was sent!</div>
        </div>
      );
    }
  }

  if (isInvalidLivePollState(livePollSession.state)) {
    return (
      <div>
        Oops an error occurred (invalid livePollSessionState{' '}
        {livePollSession.state}).
      </div>
    );
  }

  if (isStartDelay) {
    content = (
      <>
        <QuestionStartedCountDownScreen
          question={question}
          doNotPreloadImages={true}
        />
      </>
    );
  }
  return <div className={styles.container}>{content}</div>;
};
