import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/button/Button';
import livePollLogo from '../../images/livePoll_logo.svg';
import { IRespondent } from '../../models/Respondent';
import { InputName } from '../enter-pin/components/InputName';
import { postApi } from '../utils/apiUtil';
import styles from './EnterPermanentRoomScreen.module.scss';
import { SHOW_SWIPE_TUTORIAL_KEY } from '../../constants/local-storage-keys.constants';

export const NAME_MAX_LENGTH = 16;

export const EnterNamePermanentRoomScreen = () => {
  const navigate = useNavigate();
  const [name, setName] = React.useState<string>('');
  const unmountedRef = React.useRef<boolean>(false);
  const { roomId } = useParams();
  const [verifyingName, setVerifyingName] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleRespondentJoined = React.useCallback(
    (respondent: IRespondent) => {
      localStorage.removeItem(SHOW_SWIPE_TUTORIAL_KEY);
      navigate(`/room/${respondent.livePollSessionId}/${respondent.uid}`);
    },
    [navigate],
  );

  React.useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const submitForm = React.useCallback(async () => {
    setVerifyingName(true);
    try {
      const { data } = await postApi(`/take/pin/room`, {
        roomId,
        name,
      });
      handleRespondentJoined({
        ...data.respondent,
      });
    } catch (error: any) {
      if (error?.response?.status !== 404) {
        console.error(error);
      }
      setErrorMessage(error?.response?.data?.message);
    } finally {
      setVerifyingName(false);
    }
  }, [name, handleRespondentJoined, roomId]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    submitForm();
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <img alt="LivePoll Logo" src={livePollLogo} />
      <div className={styles.errorMsgContainer}>
        {errorMessage && (
          <div className={styles.errorMsgTextColor}>{errorMessage}</div>
        )}
      </div>

      <InputName text={name} onChange={handleNameChange} />

      <div className={styles.submitBtnContainer}>
        <Button label="Join" disable={verifyingName} />
      </div>
    </form>
  );
};
