import styles from './TimeUp.module.css';
export const TimeUp = () => {
  return (
    <div className={styles.container}>
      <div className={styles.circleContent}>
        <div className={styles.circle}></div>
        <span className={styles.innerCircle}></span>
        <span className={styles.line + ' ' + styles.lineRight}></span>
        <span className={styles.line + ' ' + styles.lineTop}></span>
        <span className={styles.square + ' ' + styles.square1}></span>
        <span className={styles.square + ' ' + styles.square2}></span>
        <span className={styles.square + ' ' + styles.square3}></span>
        <span className={styles.square3Line}></span>
      </div>
    </div>
  );
};
