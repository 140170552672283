import * as React from 'react';
import { ILivePollSession } from '../../models/LivePollSession';
import { LivePollType } from '../../models/LivePollType';
import styles from './QuestionFinishedScreen.module.scss';
import { useTakeLivePollState } from '../../contexts/takeLivePollStateContext';
import { ChoiceQuestionResponse } from '../../models/ChoiceQuestionResponse';
import { QuestionResult } from './components/QuestionResult';
import { QuestionType } from '../../models/QuestionType';
import { DisplayComments } from '../question-started/components/DisplayComments';
import { QuestionStartedCountDownScreen } from '../question-started-countdown/QuestionStartedCountDownScreen';
import { Airplane } from '../../components/animation/airplane/Airplane';
import { TimeUp } from '../../components/animation/timeUp/TimeUp';
import { WordCloudQuestionFinished } from './components/WordCloudQuestionFinished';
import { MultiSelectQuestionResponse } from '../../models/MultiSelectQuestionResponse';
import { SwipeQuestionResult } from './components/SwipeQuestionResult';

enum CurrentState {
  TIME_UP,
  LEADERBOARD,
  WAITING,
}

const getInitialState = ({
  questionResponse,
}: {
  livePollSession: ILivePollSession;
  questionResponse?: ChoiceQuestionResponse | MultiSelectQuestionResponse;
}) => {
  const questionNotAnswered = !questionResponse;
  const hasTimer = !!questionResponse && !!questionResponse.timeUp;
  if (questionNotAnswered && hasTimer) {
    return CurrentState.TIME_UP;
  }
  return CurrentState.WAITING;
};

const getNextState = () => {
  return CurrentState.WAITING;
};

export const QuestionFinishedScreen = () => {
  const { serverState } = useTakeLivePollState();
  const startQuestionCountdown = serverState.startQuestionCountdown;
  const livePollSession = serverState.livePollSession;
  const [showTimeUp, setShowTimeUp] = React.useState<boolean>(
    serverState.questionResponse === undefined &&
      serverState.question?.type === QuestionType.CHOICE
      ? true
      : false,
  );

  if (!livePollSession) {
    throw new Error(`livePollSession cannot be undefined`);
  }

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showTimeUp) {
      timeout = setTimeout(() => {
        setShowTimeUp(false);
      }, 3000);
    }

    // If component is unmounted we want to clear timeout
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [setShowTimeUp, showTimeUp]);

  const [currentState, setCurrenState] = React.useState<CurrentState>(
    getInitialState({
      livePollSession,
      questionResponse: serverState.questionResponse,
    }),
  );

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    // Show the time up screen for a 1.5 secs
    if (currentState === CurrentState.TIME_UP) {
      const nextState = getNextState();
      timeout = setTimeout(() => {
        setCurrenState(nextState);
      }, 3000);
    }

    // If component is unmounted we want to clear timeout
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [currentState]);

  const waitingForNextQuestionMsg = (
    <>
      <Airplane />

      <span className={styles.waitingMessage}>
        Waiting for next question...
      </span>
    </>
  );

  let content = <div>Oops an error occurred. Please refresh.</div>;

  if (currentState === CurrentState.WAITING) {
    content = waitingForNextQuestionMsg;
  }

  if (currentState === CurrentState.TIME_UP || showTimeUp) {
    content = (
      <>
        <div className={styles.timeUpAnimation}>
          <TimeUp />
        </div>
        <div className={styles.timeUpLabel}>Time's Up!</div>
      </>
    );
  }

  if (startQuestionCountdown) {
    return <QuestionStartedCountDownScreen question={startQuestionCountdown} />;
  }

  if (livePollSession.livePollType === LivePollType.QUIZ && !showTimeUp) {
    const question = serverState.question;
    const leaderboardRows = undefined;
    if (!leaderboardRows) {
      content = waitingForNextQuestionMsg;
    }

    if (question?.type === QuestionType.CHOICE) {
      return <QuestionResult />;
    } else if (question?.type === QuestionType.TEXT) {
      return <DisplayComments />;
    } else if (question?.type === QuestionType.SWIPE) {
      return <SwipeQuestionResult />;
    }
  }

  if (!showTimeUp && serverState.question?.type === QuestionType.WORD_CLOUD) {
    return <WordCloudQuestionFinished />;
  }
  return <div className={styles.container}>{content}</div>;
};
